<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1 uk-grid-small">
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3 uk-margin-remove">
              <span data-uk-icon="pencil"></span> {{ headerLabel }}
            </h3>
          </div>
          <div>
            <a href="#" class="uk-margin-right uk-button uk-button-text" @click.prevent="$router.go(-1)">abbrechen</a>
            <router-link v-if="page.id" :to="{name: 'pages-edit-builder', params:{id: page.id}}" class="uk-button uk-button-primary uk-margin-right">
               <span data-uk-icon="settings"></span> Builder öffnen
            </router-link>
            <div class="uk-button-group">
              <button
                class="uk-button uk-button-primary"
                @click.prevent="store"
                :disabled="submitting"
              >Speichern</button>
              <div class="uk-inline">
                <button class="uk-button uk-button-primary" type="button" :disabled="submitting">
                  <span uk-icon="icon: triangle-down"></span>
                </button>
                <div
                  ref="storeDropdown"
                  uk-dropdown="animation: uk-animation-slide-top-small; duration: 200;delay-hide: 0;mode: click; boundary: ! .uk-button-group; boundary-align: true;pos: bottom-right"
                >
                  <ul class="uk-nav uk-dropdown-nav">
                    <li>
                      <a href="#" @click.prevent="storeAndClose">Speichern und Schließen</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-grid" data-uk-grid>
      <div class="uk-width-expand@m">
        <div class="uk-grid uk-child-width-1-1" data-uk-grid>
          <div>
            <div class="uk-card uk-card-default uk-card-small uk-border-rounded uk-margin-large-bottom">
              <div class="uk-card-body">
                <form class="uk-form-stacked">
                  <div class="uk-margin">
                    <label class="uk-form-label">Name</label>
                    <input type="text" class="uk-input" v-model="page.title">
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Alias (SEO)</label>
                    <input type="text" class="uk-input" v-model="page.slug">
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Meta Titel</label>
                    <input type="text" class="uk-input" v-model="page.meta.title">
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Meta Beschreibung</label>
                    <textarea class="uk-textarea" v-model="page.meta.description" rows="3"></textarea>
                  </div>
                  <div class="uk-margin">
                    <label class="uk-form-label">Meta Keywords</label>
                    <input type="text" class="uk-input" v-model="page.meta.keywords">
                  </div>
                  <div class="uk-margin">
                    <div class="uk-width-medium">
                    <label class="uk-form-label">Opengraph Bild</label>
                    <select-image :source="page.meta.og_image" @image-selected="onOgImageSelected" @image-removed="onOgImageRemoved" />
                    <!--<input type="text" class="uk-input" v-model="page.meta.og_image">-->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-3@m">
        <div class="uk-sticky" data-uk-sticky="offset: 110; bottom: true; media: @m;">
          <div class="uk-card uk-card-default uk-card-small uk-border-rounded">
            <div class="uk-card-body">
              <form class="uk-form-stacked">
                <div class="uk-margin">
                  <label class="uk-form-label">Status</label>
                  <label class="uk-switch">
                    <input type="checkbox" v-model="page.active">
                    <div class="uk-switch-slider uk-switch-on-off round"></div>
                  </label>
                </div>
                <!--
                <div class="uk-margin">
                  <label class="uk-form-label">Übergeordneter Eintrag</label>
                  <treeselect no-options-text="Keine Optionen vorhanden" placeholder="Ohne" no-children-text="Keine weiteren Seiten" v-model="page.parent" :multiple="false" :options="pageTree" :normalizer="normalizer" />
                </div>
                <div class="uk-margin">
                  <label class="uk-form-label">Menü</label>
                  <v-select :options="menus" :clearable="false" v-model="page.menuId" :reduce="menu => menu.value" />
                </div>
                -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageService from '@/services/page.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
// import Treeselect from '@riophae/vue-treeselect'
// import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapActions, mapGetters } from 'vuex'
// import vSelect from 'vue-select'
import SelectImage from '@/components/finder/SelectImage'
export default {
  components: {
    // Treeselect,
    // vSelect
    SelectImage
  },
  data () {
    return {
      pageId: this.$route.params.id,
      submitting: false,
      normalizer (page) {
        return {
          id: page.id,
          label: page.title,
          children: page.children
        }
      },
      page: {
        id: null,
        menuId: 1,
        meta: {
          title: '',
          description: '',
          keywords: ''
        },
        children: []
      },
      pages: [],
      formErrors: {},
      closeAfterSave: false
    }
  },
  mounted () {
    this.$store.commit('SET_LAYOUT', 'default-layout')
    if (this.pageId) {
      this.getPage()
    }
    // this.getPages()
    // this.getNodes()
  },
  computed: {
    ...mapGetters({ menus: 'pages/nodes' }),
    headerLabel () {
      return this.pageId ? 'Seite bearbeiten' : 'Seite erstellen'
    },
    pageTree () {
      return this.pages.filter(p => p.id !== parseInt(this.pageId))
    }
  },
  methods: {
    ...mapActions({ getNodes: 'pages/getNodeList' }),
    async getPage () {
      const page = await PageService.get(this.pageId)

      this.page = page.data
    },
    async getPages () {
      const pages = await PageService.getList()

      this.pages = pages.data.data
    },
    store () {
      this.submitting = true

      let method = 'store'
      if (this.pageId) {
        method = 'update'
      }
      PageService[method](this.pageId, this.page).then(response => {
        this.page = response.data
        this.pageId = response.data.id
        this.submitting = false
        iziToast.success({
          title: 'OK',
          message: 'Seite gespeichert!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })

        if (this.closeAfterSave) {
          this.$router.replace({ name: 'pages' })
          return
        }

        if (method === 'store') {
          this.$router.replace({ name: 'pages-edit', params: { id: response.data.id } })
        }
      })
    },
    storeAndClose () {
      this.closeAfterSave = true
      this.store()
    }
  },
  onOgImageSelected (image) {
    this.page.meta.og_image = image
  },
  onOgImageRemoved () {
    this.page.meta.og_image = ''
  }
}
</script>
